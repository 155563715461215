import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'



ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// scroll.destroy()
// document.addEventListener('DOMContentLoaded', function(App) { 
//   scroll.init()
// })